// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {KVcfiUN6U: {hover: true}};

const cycleOrder = ["KVcfiUN6U", "as39hyq20"];

const serializationHash = "framer-r8Kwk"

const variantClassNames = {as39hyq20: "framer-v-11vriqa", KVcfiUN6U: "framer-v-y31vaq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Active: "as39hyq20", Inactive: "KVcfiUN6U"}

const getProps = ({height, id, tap, title, width, ...props}) => { return {...props, BABSt8cS6: title ?? props.BABSt8cS6 ?? "Tab One", OYXnukmi8: tap ?? props.OYXnukmi8, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "KVcfiUN6U"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, BABSt8cS6, OYXnukmi8, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "KVcfiUN6U", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap63rnt7 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (OYXnukmi8) {
const res = await OYXnukmi8(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-y31vaq", className, classNames)} data-framer-name={"Inactive"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"KVcfiUN6U"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap63rnt7} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgba(255, 255, 255, 0)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"KVcfiUN6U-hover": {backgroundColor: "rgb(252, 251, 248)"}, as39hyq20: {backgroundColor: "var(--token-88b568e1-e6f8-4980-bc8c-36e51a98442d, rgb(255, 255, 255))"}}} {...addPropertyOverrides({"KVcfiUN6U-hover": {"data-framer-name": undefined}, as39hyq20: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter-SemiBold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "600", "--framer-letter-spacing": "0px", "--framer-line-height": "1.5em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-e0ce79e8-f023-49ad-9edf-bfcca13d71cf, rgb(49, 47, 47)))"}}>Tab One</motion.p></React.Fragment>} className={"framer-1b4qfsh"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"zjsN4zS6L"} style={{"--extracted-r6o4lv": "var(--token-e0ce79e8-f023-49ad-9edf-bfcca13d71cf, rgb(49, 47, 47))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={BABSt8cS6} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-r8Kwk[data-border=\"true\"]::after, .framer-r8Kwk [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-r8Kwk.framer-b12qus, .framer-r8Kwk .framer-b12qus { display: block; }", ".framer-r8Kwk.framer-y31vaq { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; padding: 12px 20px 12px 20px; position: relative; width: min-content; }", ".framer-r8Kwk .framer-1b4qfsh { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-r8Kwk.framer-y31vaq { gap: 0px; } .framer-r8Kwk.framer-y31vaq > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-r8Kwk.framer-y31vaq > :first-child { margin-top: 0px; } .framer-r8Kwk.framer-y31vaq > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 112.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"as39hyq20":{"layout":["auto","auto"]},"oU2HEhCMp":{"layout":["auto","auto"]}}}
 * @framerVariables {"BABSt8cS6":"title","OYXnukmi8":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerkqrQeaO7R: React.ComponentType<Props> = withCSS(Component, css, "framer-r8Kwk") as typeof Component;
export default FramerkqrQeaO7R;

FramerkqrQeaO7R.displayName = "Tab / Style 2 Copy";

FramerkqrQeaO7R.defaultProps = {height: 51, width: 112.5};

addPropertyControls(FramerkqrQeaO7R, {variant: {options: ["KVcfiUN6U", "as39hyq20"], optionTitles: ["Inactive", "Active"], title: "Variant", type: ControlType.Enum}, BABSt8cS6: {defaultValue: "Tab One", displayTextArea: false, title: "Title", type: ControlType.String}, OYXnukmi8: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerkqrQeaO7R, [])